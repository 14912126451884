* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: var(--text);
    font: inherit;
}

li {
    list-style: none;
}

button {
    cursor: pointer;
    transition: all .3s ease;
    background-color: unset;
    border: none;
    font: inherit;


    &:active {
        transform: scale(.95);
    }
}

body {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: var(--text);
    font-weight: 500;
    background: rgba(244, 248, 251, 0.33);
}

.block_title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
}

.container {
    max-width: 1300px;
    padding: 0 15px;
    margin: 0 auto;
    width: 100%;
}

.main {
    padding: 30px 0;
}

.ant-modal-mask {
    backdrop-filter: blur(5px);
}