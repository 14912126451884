.AddModal {
  .ant-modal-body {
    padding: 20px !important;
  }
  &__head {
    text-align: center;

    &_title {
      margin-bottom: 10px;
      font-size: 26px;
      font-weight: 500;
      line-height: 39px;
      letter-spacing: 0.5px;
    }
    &_sub {
      color: rgba(0, 0, 0, 0.50);
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -4px;
    margin-right: -4px;

    &_input {
      margin: 0 4px;
      width: 75%;
    }
    &_btn {
      margin: 0 4px;
    }
  }

  &__body {
    max-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}