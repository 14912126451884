.BackNav {
    padding: 30px 0;
    &__in {
        position: relative;
        
    }

    &__link {
        font-size: 30px;
        left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__title {
        padding: 0 40px;
        text-align: center;
    }
}