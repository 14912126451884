.ShopPage {

    &__body {

        &_bc {
            display: flex;
            padding: 15px 25px;
            border-radius: 34px;
            background-color: #C1DCE8;
            margin-bottom: 35px;
            
            &_item {
                color:#636363;
                font-weight: 600;
                font-size: 16.1961px;
                line-height: 19px;
                margin: 0 10px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &_head {
            text-align: center;
            margin-bottom: 35px;
        }

        &_list {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
        }

        &_item {
            margin: 0 10px;
            margin-bottom: 20px;
            width: calc((100% / 6) - 20px);
            height: 160px;

        }
    }
}