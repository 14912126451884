.AddCategory {

    &__head {
        text-align: center;
        font-weight: 500;
        font-size: 48px;
        line-height: 56px;
    }

    &__body {
        &_form {

            &_row {
                max-width: 580px;
                width: 100%;
                margin: 0 auto;
                margin-bottom: 25px;
            }

            &_prev {
                display: flex;
                align-items: center;
                justify-content: center;
                
                &_image {
                    width: 100px;
                    height: 100px;
                    border-radius: 15px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: flex;
                    }
                }

                
            }

            &_image {
                display: flex;
                align-items: center;
                justify-content: center;
                
                &_input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    visibility: hidden;
                    pointer-events: none;
                    touch-action: none;
                }

                &_label {
                    cursor: pointer;
                    width: 100px;
                    height: 100px;
                    border-radius: 15px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--aqua);
                    font-size: 40px;
                    line-height: 40px;
                }
            }
        }
    }
}