.StatPage {

    &__in {

    }

    &__body {
        
        &_head {
            text-align: center;
            margin-bottom: 20px;
        }
    }
}