.StatBody {

    &__date {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    &__head {
        text-align: center;
        margin-bottom: 20px;
    }

    &__list {
        
    }
}