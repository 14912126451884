.ShopAdd {
    height: 160px;
    background-color: var(--aqua);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    width: 100%;
    &__icon {
        font-size: 40px;
        line-height: 40px;
    }


}