.MapPage {

  &__in {

  }

  &__title {
    margin-bottom: 20px;
    text-align: center;
  }
}