.StatItem {
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 1px 4px 7px rgba(13, 153, 232, 0.14);
    border-radius: 22px;
    padding: 15px;

    &__name {
        font-weight: 500;
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 15px;
    }

    &__body {
        &_item {
            display: flex;
            margin-bottom: 15px;

            &_name {
                margin-right: 20px;
                font-weight: 400;
                font-size: 24px;
                line-height: 160%;
                /* or 38px */


                color: rgba(64, 64, 64, 0.7);
            }

            &_value {
                color: var(--aqua);
                font-weight: 700;
                font-size: 32px;
                line-height: 160%;
            }
        }
    }
}