.GraveModal {
  .ant-modal-body {
    padding: 25px !important;
  }
  &__head {
    text-align: center;
    &_title {
      font-size: 26px;
      font-weight: 500;
      line-height: 40px;
      line-height: 150%;
    }
    &_date {
      color: rgba(0, 0, 0, 0.50);
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
    }
  }

  &__item {
    font-size: 24px;
    line-height: 28px;

    &_label {
      font-weight: 400;
      margin-bottom: 5px;
    }

    &_value {
      font-weight: 500;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}