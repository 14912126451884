.modal {
    
    .ant-modal-content {
        border-radius: 43px;
        overflow: hidden;
        .ant-modal-close {
            display: none;
        }

        .ant-modal-body {
            background: #FFFFFF;
            box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.01);
            padding: 60px 100px;
            
        }

        .ant-modal-footer {
            display: none;
        }
    }

    &__close {
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
}