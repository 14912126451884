:root {
    --blue: #0D99E8;
    --gray: #F4F4F4;
    --gray_border: #DEDEDE;
    --dark_gray: #9C9C9C;
    --text: #404040;
    --green: #00D67C;
    --purp: #8512CC;
    --aqua: #10A5F9;
    --orange: #F98B69;
    --red: #FF4B4B;
    --light_red: #F46D6D;
    --dark_aqua: #C1DCE8;


}