.EmptyList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    color: #cdcdcd;

    &__icon {
        font-size: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    &__text {
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
    }
}