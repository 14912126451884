.AddModalItem {
  border-radius: 21px;
  border: 2px solid #DEDEDE;
  background-color: #fff;
  transition: all .3s ease;
  cursor: pointer;

  &:hover {
    background-color: #DEDEDE;
  }

  &__in {
    padding: 12px 18px;  
  }

  &__role {
    margin-bottom: 8px;
    color: #0D99E8;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__name {
    font-size: 20px;
    font-weight: 500;
    color:#303030;
    margin-bottom: 8px;
  }
  
  &__date {
    font-size: 18px;
    color: #303030;
  }
}

.AddModalItem.active {
  background-color: #5cb9ef;
  border-color: #5cb9ef;
  
  .AddModalItem__name {
    color: #fff;
  }

  .AddModalItem__role {
    color: #fff;
  }

  .AddModalItem__date {
    color: #fff;
  }
   
}