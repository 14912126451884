.Button {
    padding: 20px 50px;
    border-radius: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    

    &:disabled {
        opacity: .5;
        pointer-events: none;
        touch-action: none;
    }
    
    &__text {
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        font-weight: 600;
    }

    &__load {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__badge {
        border-radius: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        font-size: 14px;
        line-height: 14px;
        color: #fff;
        font-weight: 700;
        position: absolute;
        right: -5px;
        top: -5px;
        background-color: red;
    }
}

.Button.load {
    opacity: .5;
    pointer-events: none !important;
    touch-action: none !important;
}

.Button.default {
    background-color: var(--text);
}

.Button.danger {
    background-color: var(--light_red);
}

.Button.primary {
    background-color: var(--blue);
}

.Button.sm {
    padding: 12px 30px;
    border-radius: 33px;

    .Button__text {
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
    }
}

.Button.success {
    background-color: var(--green);
}

