.ChatPage {


    &__body {

        &_head {
            text-align: center;
            margin-bottom: 50px;


            
        }

        &_chat {
            background: #FFFFFF;
            box-shadow: 1px 4px 8px rgba(16, 45, 150, 0.21);
            border-radius: 22px;
            max-width: 1000px;
            width: 100%;
            overflow: hidden;
            height: 800px;
            margin: 0 auto;
            display: flex;
            position: relative;
            

            &_side {
                border-right: 1px solid rgba(0, 0, 0, 0.22);
                width: 30%;
                height: 800px;
                overflow-y: auto;
            }

            &_area {
                width: 70%;
                height: 800px;
                overflow-y: auto;

                

                &_head {
                    padding: 20px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.22);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    &_name {
                        font-weight: 600;
                        font-size: 32px;
                        line-height: 38px;
                        margin-bottom: 20px;
                        text-transform: capitalize;
                    }
        
                    &_phone {
                        font-weight: 600;
                        font-size: 21.125px;
                        line-height: 25px;
                        color: #0D99E8;
                    }

                }

                &_list {
                    padding: 20px;
                    padding-bottom: 115px;
                }

                &_item {
                    margin-bottom: 15px;
                }


                &_text {
                    display: flex;
                    align-items: center;
                    width: 70%;
                    padding: 20px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 100;


                    .InputB {
                        width: 100%;
                    }

                    &_btn {
                        height: 65px;
                        width: 65px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--aqua);
                        border-radius: 100%;
                        flex: 0 0 auto;
                        margin-left: 15px;
                        color: #fff;
                        font-size: 30px;
                        line-height: 30px;


                        &:disabled {
                            opacity: .5;
                            pointer-events: none;
                            touch-action: none;
                        }
                    }

                    &_btn.load {
                        opacity: .5;
                        pointer-events: none;
                        touch-action: none;
                    }
                }
            }
        }
    }
}