.OrdersHead {
    margin-bottom: 40px;
    &__title {
        text-align: center;
        margin-bottom: 40px;
    }

    &__body {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        max-width: 450px;
        width: 100%;
        margin: 0 auto;

        &_item {

        }
    }
}