.UserItem.active {
    .UserItem__main {
        background-color: var(--aqua);
    }
    .UserItem__chat {
        background-color: var(--aqua);
    }
}

.UserItem {
    color: #fff;
    &__main {
        background-color: var(--text);
        padding: 20px;
        border-radius: 20px;
        width: 100%;
        height: 100%;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        &_name {
            margin-bottom: 15px;
        }

        &_info {

            &_item {
                display: flex;
                margin-bottom: 10px;
                &_name {
                    margin-right: 10px;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 21px;
                    padding-top: 3px;
                }
                &_value {
                    font-weight: 600;
                    font-size: 23.7273px;
                    line-height: 28px;
                }
            }
        }
    }

    &__chat {
        background-color: var(--text);
        padding: 20px;
        border-radius: 20px;
        width: 100%;
        height: 100%;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}