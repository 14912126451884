.Push {

    &__head {
        text-align: center;
        margin-bottom: 80px;
        font-weight: 500;
        font-size: 48px;
        line-height: 56px;
    }

    &__body {

        
    }
}