.DocItem {
    background: #FFFFFF;
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    position: relative;
    height: 100%;

    &__delete {
        width: 35px;
        height: 35px;
        background-color: var(--red);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        line-height: 20px;
        color: #fff;
        bottom: -10px;
        right: -10px;
        position: absolute;
        border-radius: 100%;
    }

    &__name {
        padding: 20px;
        font-weight: 600;
        font-size: 16.1961px;
        line-height: 19px;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}