.FaqItem {
    background: #FFFFFF;
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    height: 145px;
    cursor: pointer;
}