.MapPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 15px;

  &__in {
    padding: 20px;
    backdrop-filter: blur(10px);
    border-radius: 35px;
    background-color: rgba(#ccc, .6);
  }

  &__part.MapPanel__part--last {
    // justify-content: flex-end;
    margin: 0 -4px 0 auto;
  }

  &__part {
    display: flex;
    margin-left: -4px;
    margin-right: -4px;
    width: 80%;

    

    &_input {
      margin: 0 4px;
      flex: 1 0 auto;
      position: relative;

      &_result {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
        background-color: #fff;
        z-index: 3;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        max-height: 350px;
        overflow-y: auto;
        border-radius: 20px;
        border: 1px solid #DEDEDE;
        background-color: #fff;

        &_item {
          padding: 20px;
          font-size: 20px;
          font-weight: 500;
          line-height: normal;
          cursor: pointer;
          transition: all .3s ease;

          &:hover {
            color: var(--blue);
          }
        }
      }
    }
    &_action {
      margin: 0 4px;

      button {
        white-space: nowrap;
      }
    }
  }
}