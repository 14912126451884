.Header {
    &__in {
        padding: 30px 0;
    }


    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_logo {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 150px;
            height: 60px;
            font-weight: 700;
            font-size: 18px;
            line-height: 150%;
        }

        &_logout {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 20px;
            font-weight: 600;
            color: #fff;
            background-color: var(--blue);
            border-radius: 30px;

            &_text {
                margin-left: 15px;
            }
        }
    }

    &__main {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 20px;

        &_nav {
            display: flex;
            align-items: center;


            &_item.active {
                color: var(--blue);
            }

            &_item {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                line-height: 27px;
                font-weight: 500;
                color: var(--dark_gray);
                margin-left: 30px;

                &_icon {
                    line-height: 18px;
                }

                &_text {
                    margin-left: 10px;
                    
                }
            }
        }
    }
}