.MapAction {
  display: flex;
  align-items: center;
  justify-content: center;

  &__in {
    max-width: 570px;
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    margin-left: -4px;
    margin-right: -4px;
  }

  &__input {
    margin: 0 4px;
    position: relative;
    width: 100%;
  }

  &__btn {
    margin: 0 4px;
  }

  &__result {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    border: 1px solid red;
    max-height: 350px;
    overflow-y: auto;
    border-radius: 20px;
    border: 1px solid #DEDEDE;
    background-color: #fff;
    width: 100%;
    z-index: 1000;

    &_loader {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }

    &_item {
      padding: 20px;
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
        color: var(--blue);
      }
    }
  }
}