.OrdersList {

    &__head {
        text-align: center;
        margin-bottom: 35px;
    }

    &__search {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
    }


    &__body {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;

        &_item {
            margin-bottom: 15px;
        }
    }
}