.FaqPage {

    

    &__body {
        &_head {
            text-align: center;
            margin-bottom: 80px;
        }

        &_list {

        }

        &_add {
            border-radius: 15px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            line-height: 30px;
            color: #fff;
            background-color: var(--aqua);
            height: 145px;
        }
    }
}