.NewOrdersPage {

    &__in {

    }

    &__body {

        &_item {
            margin-bottom: 15px;
        }
    }
}