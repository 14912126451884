.AddCm {

    .ant-modal-body {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    &__head {

    }

    &__action {
        margin-top: 30px;
    }
}