.OrderCard {
    background: #FFFFFF;
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px;

    &__date {   
        text-align: left;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        /* identical to box height */


        color: rgba(64, 64, 64, 0.7);
    }

    &__name {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        word-break: break-all;
    }

    &__price {
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        text-align: right;

        color: #2FBEEB;
    }
}