.ShopServ {
    position: relative;
    height: 280px;
    border-radius: 15px;
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.08);
    overflow: hidden;

    &__edit {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: var(--aqua);
        display: flex;
        align-items: center;
        justify-content: center;

        color: #fff;

        font-size: 30px;
        line-height: 30px;
    }

    &__img {
        overflow: hidden;
        height: 205px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__name {
        padding: 15px;
        font-weight: 500;
    }
}